(function($) {
  var LISTENERS = {

    init: function() {
      $('[data-toggle="tooltip"]').tooltip({animation:false});
      $('[data-toggle="popover"]').popover({ trigger: "manual" , html: true, animation:true})
        .on("mouseenter", function () {
            var _this = this;
            $(this).popover("show");
            $(".popover").on("mouseleave", function () {
                $(_this).popover('hide');
            });
        }).on("mouseleave", function () {
            var _this = this;
            setTimeout(function () {
                if (!$(".popover:hover").length) {
                    $(_this).popover("hide");
                }
            }, 300);
        }); 

    var lastScrollTop = $(window).scrollTop();;
    var dir;
    $(window).scroll(function(event) {
      valorScroll=$(window).scrollTop();

      // añade clase "enpantalla" cuando aparece en pantalla
      $(".js-aparece-scroll").each(function(index, el) {
        if ($(this).offset().top+$(this).height()<=valorScroll+$(window).height()){
          $(this).addClass("enpantalla");
        }else{
          $(this).removeClass("enpantalla");
        }
      });

      //reducido cabecera
          dir = (valorScroll > lastScrollTop && valorScroll>0) ? 1 : -1;
      if(dir>0){
       if (valorScroll>50){
          $("header").addClass('sticky-down');
          $("header").removeClass('sticky-up');
        }else{
          $("header").removeClass('sticky-down');
          $("header").removeClass('sticky-up');
        }
      }else{
       if (valorScroll>50){
          $("header").addClass('sticky-up');
          $("header").removeClass('sticky-down');
        }else{
          $("header").removeClass('sticky-down');
          $("header").removeClass('sticky-up');
        }
      }
      lastScrollTop = valorScroll;

    });



      $(".js-mostrarModalCheck").click(function(event) {
        txt=$(this).closest(".form-group").find(".txtModal").html();
        $("#modalAlerta .modal-title").html("");
        $("#modalAlerta .modal-body").html(txt);
        $("#modalAlerta").modal();        
      });


      /**
       * LISTENER validador formularios
       */
      // $('.js-form-validator').validator(customValidations()).on('submit', function (e) {
      //   if (e.isDefaultPrevented() ) {
      //     $(this).find('.has-error').each(function(index, element){
      //       // console.log("hay errores");
      //     });
      //   }
      // });

      /**
       * LISTENER para mostrar/ocultar objetos
       */
      $(".js-toggle-div").click(function(){
        var obj=$(this).data("toggle-div");
        $(obj).toggleClass("desactivo");
      })

      /**
       * LISTENER menus con ancla
       */
      $(".js-menu-ancla").click(function(e){
        e.preventDefault();
        var ancla=$(this).attr("href");
        var offset=$(this).data("menuancla-offset");
        if(offset=="" || offset==undefined){
          offset=0;
        }
        PLG_scrollTo(1,ancla,0,offset,0);
      })

      /**
       * LISTENER menus con ancla
       */
      $(".js-li-ancla > a").click(function(e){
        e.preventDefault();
        var ancla=$(this).attr("href");
        var offset=$(this).data("menuancla-offset");
        var isHomeLink=$(this).closest(".js-li-ancla").hasClass("home-link")?true:false;
        if(isHome && isHomeLink){
          ancla=ancla.replace("/#", "#");
        }
        if(offset=="" || offset==undefined){
          offset=0;
        }
        if(isHomeLink && !isHome){
          document.location.href=ancla;
        }else{
          PLG_scrollTo(1,ancla,0,offset,0);
          $("header .navbar-toggler").trigger("click");
        }
      })

      /**
       * LISTENER para despliegue menú hamburguesa
       */
      $('#menu_desplegable').on('show.bs.collapse', function () {
        PLG_showMenu();
        PLG_closeModalLogin();
      });

      $("#menu_desplegable .close").click(function(){
        PLG_closeMenu();
      });

      /**
       * LISTENER para mostrar modal login
       */
      $(".js-btn-header-login").click(function(){
        if($("#menu_desplegable").hasClass("show")){
          PLG_closeMenu(function(){
             PLG_toggleModalLogin();
          });
        }else{
          PLG_toggleModalLogin();
        }
      });

      /**
       * LISTENER para mostrar modal alerta
       */
      $(".js-modal-alerta").click(function(e){
        e.preventDefault();
        PLG_showModalAlerta($(this).data("alerta-titulo"),$(this).data("alerta-texto"));
      });

      /**
       * LISTENER cierre cookies
       */
      $("#modal-cookies .close").click(function(){
        $("#modal-cookies").fadeOut();
        setCookie("congreso.aslan.com-cookies", "true", 365);
      });
      checkCookie();

      /**
       * LISTENER copiar de un input
       */
      $(".js-btn-copy").click(function() {
          var target=$(this).data("target-copy");
          copyToClipboard(document.getElementById(target));
      });  

      $(".js-play-video").click(function(){
        var videoUrl=$(this).data("video-url");
        var videoUrl_ogg=$(this).data("video-url-ogg");
        var videoUrl_webm=$(this).data("video-url-webm");
        var myVideo = document.getElementById("videoPlayer"); 
            $("#videoPlayer source.mp4").attr('src', videoUrl);
            $("#videoPlayer source.ogg").attr('src', videoUrl_ogg);
            $("#videoPlayer source.webm").attr('src', videoUrl_webm);
            myVideo.load();
            myVideo.play();
        $("#modalVideo").show();
      })
      $("#modalVideo .close").click(function(){
        var myVideo = document.getElementById("videoPlayer"); 
            myVideo.pause();
        $("#modalVideo").hide();
      })

    }
  };
 // Attach function to window
  window.LISTENERS = LISTENERS;
})(jQuery);