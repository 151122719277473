/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var anchoventana;
var altoventana;
var md=768;
var show_thanks;

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        /**
         * declaracion variables globales
         */
          anchoventana=$(document).width();
          altoventana=$(document).height();
          md=768;

        /**
         * init plugins y listeners comunes de proyectos
         */
         PLUGINS.init();
         LISTENERS.init();

        /**
         * project JS
         */        

        /* carrusel testimonials */
        var swiper = new Swiper('.swiper-testimonials', {
            slidesPerView: 1,
            navigation: {
              nextEl: '.button-next-testimonial',
              prevEl: '.button-prev-testimonial',
            },
            pagination: {
              el: '.pagination-testimonial',
              clickable: true,
            },
        });
        if($(".swiper-testimonials .swiper-wrapper .swiper-slide").length == 1) {
            $('.swiper-testimonials .swiper-wrapper').addClass( "disabled" );
            $('.swiper-testimonials .swiper-pagination').addClass( "disabled" );
            $('.swiper-testimonials .swiper-button-prev').addClass( "disabled" );
            $('.swiper-testimonials .swiper-button-next').addClass( "disabled" );
            $('.swiper-testimonials .swiper-pagination').css("opacity",0);
            $('.swiper-testimonials .swiper-button-prev').css("opacity",0);
            $('.swiper-testimonials .swiper-button-next').css("opacity",0);
        }

        /* carrusel experts */
        var swiper = new Swiper('.swiper-experts', {
            slidesPerView: 1,
            autoHeight: true, //enable auto height
            navigation: {
              nextEl: '.button-next-experts',
              prevEl: '.button-prev-experts',
            },
            pagination: {
              el: '.pagination-experts',
              clickable: true,
            },
        });
        if($(".swiper-experts .swiper-wrapper .swiper-slide").length == 1) {
            $('.swiper-experts .swiper-wrapper').addClass( "disabled" );
            $('.swiper-experts .swiper-pagination').addClass( "disabled" );
            $('.swiper-experts .swiper-button-prev').addClass( "disabled" );
            $('.swiper-experts .swiper-button-next').addClass( "disabled" );
            $('.swiper-experts .swiper-pagination').css("opacity",0);
            $('.swiper-experts .swiper-button-prev').css("opacity",0);
            $('.swiper-experts .swiper-button-next').css("opacity",0);
        }


        /* add class hover to collapse menu*/
        $(".collapse ul li a").addClass("hvr-underline-reveal");

        /* listener click carrousel banners*/
        var swiperBanners = new Swiper('.swiper-banners', {
            slidesPerView: 1,
            pagination: {
              el: '.pagination-banners',
              clickable: true,
            },
        });
        $(".js-pag-menu").click(function(event) {
          var slide=$(this).data("slide");
          swiperBanners.slideTo(slide);
          $(".js-pag-menu").removeClass("activo");
          $(this).addClass("activo");
        });

        /*show thankyou contact form*/
        if(show_thanks){
          setTimeout(function(){
            PLG_scrollTo(1,"#contact-us","0",1);
          },300);
        }
        $("#modal-thankyou .close").click(function(event) {
          $("#modal-thankyou").fadeOut();
          $(this).closest("section").removeClass('show-thanks');
        });

        $('.btImprimir').on('click', function(){
         /* _ids = [];
          $(".collapse.show").each(function(){
                  _ids.push($(this).attr('data-indice'));
          });
          json_ids = JSON.stringify(_ids);
          $('#ids_pdf').val(json_ids);*/
          //$('#fGenerarPDF').submit();
        });

        $('.btImprimirDates').on('click', function(){
         /* _ids = [];
          $(".collapse.show").each(function(){
                  _ids.push($(this).attr('data-indice'));
          });
          json_ids = JSON.stringify(_ids);
          $('#ids_pdf').val(json_ids);*/
          //$('#fGenerarPDF').submit();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
