(function($) {
  var PLUGINS = {
    init: function() {
      //algunos plugins interesa solo inicializarlos en mobile
      if (anchoventana>=md){
          /*animaciones WOW*/
            new WOW().init();
            
            // $( document ).mousemove( function( e ) {

            //   ancho=$(window).width()/2;
            //   alto=$(window).height()/2;
            //   posx=e.clientX-ancho;
            //   posy=e.clientY-alto;
            //   pctx=parseInt(posx*100/ancho,10);
            //   pcty=parseInt(posy*100/alto,10);

            //   $('.js-parallaxElement').each(function(index, el){

            //     tempx=pctx*parseInt($(el).attr("data-movimientopxx"),12)/100*-1;
            //     tempy=pcty*parseInt($(el).attr("data-movimientopxy"),12)/100*-1;

            //     $(el).css({
            //       '-ms-transform': 'translate('+tempx+'px, '+tempy+'px)',
            //       '-webkit-transform-style': 'preserve-3d',
            //       '-webkit-backface-visibility': 'hidden',
            //       '-webkit-transform': 'translate('+tempx+'px, '+tempy+'px)',
            //       'transform': 'translate('+tempx+'px, '+tempy+'px)'
            //     });

            //   });

            // });          

            /* scroll parallax */
            //var s = skrollr.init();          
      }

      /* personaliza el check con un ON-OFF */
      // var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
      // elems.forEach(function(html) {
      //   var switchery = new Switchery(html,{
      //     color : '#f1426e'
      //   });
      // });

      /* personaliza un slider */
      // $("#slide").ionRangeSlider({
      //     type: "single",
      //     min: 0,
      //     max: 50,
      //     from: 1,
      //     step: 5,
      //     grid: true,
      //     grid_snap: true,
      //     values:["1","10","20","30","40","50"],
      //     grid_num: 5,
      //     postfix: "km"
      // });

      /* el combo a un formato con más opciones */
      // $(".chosen-select:not(.chosen-select-especial)").chosen({disable_search_threshold: 10});

      /* personaliza el check */
      $('.i-checks').iCheck({
        checkboxClass: 'icheckbox_square-green',
        radioClass: 'iradio_square-green',
      });

      /* personaliza un campo numerico con + y - */
      // $(".touchspin").TouchSpin({
      //     buttondown_class: 'btn btn-white',
      //     buttonup_class: 'btn btn-white',
      //     min:1,
      //     max:100
      // });

      /* carrusel swiper */
       var swiper = new Swiper('.swiper-testimonials', {
            pagination: '.swiper-pagination',
            slidesPerView: 1,
            paginationClickable: true, 
            nextButton:".swiper-button-next",
            prevButton:".swiper-button-prev",
            loop: false,
            
       });


    }

  };
 // Attach function to window
  window.PLUGINS = PLUGINS;
})(jQuery);

(function ($) {
    $.fn.mostrar_msg = function(_title, _msg){
        if ($(this).find('.modal-title').length && $(this).find('.modal-body').length){
            $(this).find('.modal-title').html(_title);
            $(this).find('.modal-body').html('<p>'+_msg+'</p>');
            $(this).modal();
        } else {
            alert(_title+": "+_msg);
        }
    };
 
}(jQuery));
